import React, { useContext, useEffect, useState } from "react";
import EditImage from "../images/edit.png";
import DeleteImage from "../images/delete.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";
import moment from "moment";
import { AuthContext } from "../context/authContext";
import { api } from "../components/api";

const Single = () => {
  const [post, setPost] = useState({});

  const location = useLocation();
  const navigate = useNavigate();
  const postId = location.pathname.split("/")[2];
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/posts/${postId}`);
        setPost(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  const handleDelete = async () => {
    try {
      await api.delete(`/posts/${postId}`);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  return (
    <div className="single">
      <div className="content">
        {post.img && (
          <img src={`../upload/${post.img}`} alt="post cover" />
        )}
        <div className="user">
          {post.userImg && <img src={post.userImg} alt="user" />}
          <div className="info">
            {post.username && (
              <span>{post.username}</span>
            )}
            {post.date && (
              <p>Posted {moment(post.date).fromNow()}</p>
            )}
          </div>
          {currentUser && post.username === currentUser.username && (
            <div className="edit">
              <Link to={`/write?edit=2`} state={post}>
                <img src={EditImage} alt="edit" />
              </Link>
              <img onClick={handleDelete} src={DeleteImage} alt="delete" />
            </div>
          )}
        </div>
        {post.title && (
          <h1>{post.title}</h1>
        )}
        {post.desc && (
          <i>"{getText(post.desc)}"</i>
        )}
      </div>
      <Menu cat={post.cat} />
    </div>
  );
};

export default Single;
